.app_content {
	min-height: calc(100vh - 117.5px);
}

video {
  position: fixed;
  z-index: -1;
  width: 100%;
  object-fit: fill;
}

.App {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  width: 100%;
}

.centered-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically */
    height: 100vh;          /* Full viewport height */
}

.hero-animation {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.fade-in-component {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-component.loaded {
  opacity: 1;
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

body {
  background-color: #ffffff;
}

#logo {
  transition: all ease 500ms;
}

#logo:hover {
  transform: scale(1.1);
  opacity: 0.7;
}

footer a {
  color: inherit;
  outline: none;
}

#slider-dark .slick-prev:before, #slider-dark .slick-next:before {
	color: #262626 !important;
}

#slider-light .slick-dots button:before {
	color: #fefefe !important;
}

.gallery img:hover {
  transition: all ease 500ms;
  transform: scale(1.1);
}

.gallery .slick-prev {
	left: -20%;
}

.gallery .slick-next {
	right: -18%;
}

.slick-prev {
	z-index: 1;
}

.slick-prev:before, .slick-next:before {
	font-size: 30px !important;
}

.card,
.card-img,
.card-img-top {
  border-top-left-radius: calc(0rem);
  border-top-right-radius: calc(0rem);
  border-radius: 0.25rem;
}

.card-img-top {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
}

.rounded {
  border-radius: 0rem !important;
}

.card .bg-image {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

.card-body {
  padding: 1rem 1rem;
}

.btn {
  border-radius: 0.15rem;
}